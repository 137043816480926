import { useRef } from 'react';
import toZanarkandAudio from '../assets/to-zanarkand.mp3';

const handleVolume = (audioEl, rangeEl) => {
    audioEl.current.volume = rangeEl.current.value;
}

const handleHeadshellClick = (audioEl) => {
    if (audioEl.current.paused) {
        audioEl.current.play();
        audioEl.current.currentTime = 0;
    } else {
        audioEl.current.pause();
    }
}

export const RecordPlayer = ({ className, style, isPlayerHidden }) => {
    const audioEl = useRef(null);
    const rangeEl = useRef(null);

    return (
        <div className={`${className} ${isPlayerHidden ? 'hidden' : ''}`} style={style}>
            <div className="record-player">
                <input type="checkbox" id="headshell" onClick={() => handleHeadshellClick(audioEl)} />

                <label className="headshell" htmlFor="headshell"></label>

                <audio id="player" preload="auto" ref={audioEl} loop>
                    <source src={toZanarkandAudio} type="audio/mpeg"/>
                </audio>

                <input type="range" max="1" min="0" step="0.1" id="volume-control" ref={rangeEl} onChange={() => handleVolume(audioEl, rangeEl)} />

                <div className="plinth"></div>

                <div className="platter"></div>

                <div className="vinyl"></div>

                <div className="top-circle"></div>
            </div>
        </div>
    );
}