
import { useState } from "react";

import { RecordPlayer } from "../components/RecordPlayer";

export const Intro = ({ activeScene, setActiveScene }) => {
    const [isPlayerHidden, setIsPlayerHidden] = useState(false);

    const handleContinueClick = () => {
		setIsPlayerHidden(true);
        setActiveScene('birthdayCard');
	}

    return (
        <div className={`container flex flex-col text-[22px] px-6 text-white text-center gap-y-2 md:p-10 md:text-[28px] lg:text-[40px] ${activeScene === 'intro' ? '' : 'hidden'}`}>
            <div className="animate__animated animate__fadeIn animate__slower">hello, sister.</div>

            <div className="animate__animated animate__fadeIn animate__slower" style={{ animationDelay: '1.5s'}}>today is your (26th) birthday. and what a day it is!</div>

            <div className="animate__animated animate__fadeIn animate__slower" style={{ animationDelay: '3s'}}>i wanted to say a little something for you on this, your special day.</div>

            <div className="animate__animated animate__fadeIn animate__slower" style={{ animationDelay: '4.5s'}}>but, before i do, let's get some music going, for ambience.</div>

            <RecordPlayer className="animate__animated animate__fadeIn animate__slower flex items-center justify-center overflow-hidden" style={{ animationDelay: '6s'}} isPlayerHidden={isPlayerHidden} />

            <div className="animate__animated animate__fadeIn animate__slower text-[16px]" style={{ animationDelay: '7.5s'}}>
                click the arm to play. drag the slider on the right side up and down to control volume.
            </div>

            <div className="animate__animated animate__fadeIn animate__slower text-[16px]" style={{ animationDelay: '7.5s'}}>
                once you've got the tunes just right, <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={handleContinueClick}>click here</button> to continue.
            </div>
        </div>
    );
}

export default Intro;