
import { useState } from "react";

import BirthdayCake from "../components/BirthdayCake";

export const Final = ({ activeScene, setActiveScene }) => {
    const [isPlayerHidden, setIsPlayerHidden] = useState(false);

    return (
        <div className={`container flex flex-col text-[22px] px-6 text-white text-center gap-y-2 md:p-10 md:text-[28px] ${activeScene === 'final' ? '' : 'hidden'}`}>
            <div className="animate__animated animate__fadeIn animate__slower">we've lived through some difficult times, sister, you and i. but also wonderful. and bizarre. and hilarious.</div>

            <div className="animate__animated animate__fadeIn animate__slower" style={{ animationDelay: '1.8s'}}>i say to you now, with no exaggeration, that my life is immeasurably better for having you in it.</div>

            <div className="animate__animated animate__fadeIn animate__slower" style={{ animationDelay: '3.6s'}}>(i hope you can hear the opening chords of "for good" playing in your head)</div>

            <div className="animate__animated animate__fadeIn animate__slower" style={{ animationDelay: '5.1s'}}>now, i have just one last thing for you.</div>

            <BirthdayCake className="animate__animated animate__fadeIn animate__slower top-[60px]" style={{ animationDelay: '6.3s', margin: '0 auto'}} />


            <div className="animate__animated animate__fadeIn animate__slower text-[18px] mt-[86px]" style={{ animationDelay: '7.2s'}}>happy birthday, sofia.</div>

            <div className="animate__animated animate__fadeIn animate__slower text-[18px]" style={{ animationDelay: '8s'}}>with immeasurable love, from your brother, gabriel.</div>

            <div className="animate__animated animate__fadeIn animate__slower text-[12px]" style={{ animationDelay: '9.2s'}}>we'll always have our "sweet secrets from our bum bums". always, and forever.</div>
        </div>
    );
}

export default Final;