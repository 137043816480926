	
import { useState } from "react";
import ReactPlayer from 'react-player'

import CardPage from "../components/CardPage";

export const BirthdayCard = ({ activeScene, setActiveScene }) => {
    return (activeScene === 'birthdayCard' && (
        <div className="birthdayCard">
            <CardPage isLastPage={true} setActiveScene={setActiveScene}>  
                <div className="relative top-[160px]">
                    <ReactPlayer width={'600px'} height={'320px'} url={'assets/last-night.mp4'} controls={true} />

                    <p>i sound <em>terrible</em> in this video, but i had to include it because you sound wonderful. that's how you know my love for you is true.</p>
                </div>
            </CardPage>

            <CardPage>  
                <div className="relative top-[160px]">
                    <ReactPlayer width={'600px'} height={'320px'} url={'assets/aida-2.mp4'} controls={true} />
                </div>
            </CardPage>

            <CardPage>  
                <div className="relative top-[160px]">
                    <ReactPlayer width={'600px'} height={'320px'} url={'assets/aida-1.mp4'} controls={true} />
                </div>
            </CardPage>

            <CardPage>  
                <div className="relative top-[160px]">
                    <ReactPlayer width={'600px'} height={'320px'} url={'assets/sofia-tmg.mp4'} controls={true} />
                </div>
            </CardPage>

            <CardPage>  
                <div className="relative top-[160px]">
                    <ReactPlayer width={'600px'} height={'320px'} url={'assets/love-me.mp4'} controls={true} />
                </div>
            </CardPage>

            <CardPage>  
                <div className="relative top-[160px]">
                    <ReactPlayer width={'600px'} height={'320px'} url={'assets/justice.mp4'} controls={true} />
                </div>
            </CardPage>

            <CardPage>  
                <div className="relative top-[160px]">
                    <ReactPlayer width={'600px'} height={'320px'} url={'assets/hi-there.mp4'} controls={true} />
                </div>
            </CardPage>
            
            <CardPage>  
                <div className="relative top-[160px]">
                    <ReactPlayer width={'600px'} height={'320px'} url={'assets/leggy.mp4'} controls={true} />
                </div>
            </CardPage>
            
            <CardPage>  
                <div className="relative top-[160px]">
                    <ReactPlayer width={'600px'} height={'320px'} url={'assets/too-short.mp4'} controls={true} />
                </div>
            </CardPage>

            <CardPage>  
                <div className="relative top-[160px]">
                    <ReactPlayer width={'600px'} height={'320px'} url={'assets/bizarre.mp4'} controls={true} />
                </div>
            </CardPage>

            <CardPage>  
                <div className="relative top-[160px]">
                    <ReactPlayer width={'600px'} height={'320px'} url={'assets/rockband.mp4'} controls={true} />
                </div>
            </CardPage>

            <CardPage>
                <p>these days, i am truly lucky to find myself in the curious position of having frequent reasons to write heartfelt notes for the people i care deeply about. when i write such notes, i like to take the opportunity to share memories that mean something to me. so, let's take a stroll down memory lane, you and i.</p>

                <p>the following is just a slice of the shit we've gotten up to. there's much more besides this, and i'm sure there will be countless more in the days to come.</p>

                <p>(also, make sure to pause your current video before flipping to the next one, otherwise things will get janky. you can always refresh this page if the sound gets really weird)</p>
            </CardPage>

            <CardPage>
                <p>sofia. sister of mine. level 30 enchantress.</p>

                <p>first, and most importantly: happy birthday. welcome to the other side of 25. i think you'll find that the more time you spend here, the more you realize it's not so bad getting older and growing up. in times like these:, there's these mountain goats lyrics i think about:</p>

                <p className="flex flex-col gap-y-1">
                    <em>It's good to be young, but let's not kid ourselves</em>
                    <em>It's better to pass on through those years and come out the other side</em>
                    <em>With our hearts still beating</em>
                    <em>Having stared down demons</em>
                    <em>And come back breathing</em>
                </p>

                <p>it comes to mind when i think about you, me, the place we came from, and the people we are now.</p>
            </CardPage>

            <CardPage>
                <h3 className="happy text-[48px] relative top-[16rem]">sofia</h3>
            </CardPage>
        </div>
    ));
}

export default BirthdayCard;