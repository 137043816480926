import { useState } from "react";

const CardPage = ({ children, isLastPage, setActiveScene }) => {
    const [isPageTurned, setIsPageTurned] = useState(false);

    const handlePageTurn = () => {
        if (isLastPage) {
            setActiveScene('final');
        } else {
            setIsPageTurned(true);
        }
    }

    return (
        <div className={`cardFront absolute text-[16px] overflow-y-hidden ${isPageTurned ? 'turned-page' : ''}`}>
            <div className={`${isPageTurned ? 'invisible' : 'visible'}`}>
                {children}

                <span className="absolute right-0 bottom-0 text-[48px] cursor-pointer transition-all hover:text-[#33cc33]" onClick={() => handlePageTurn(true)}>⮕</span>
            </div>
        </div>
    );
};

export default CardPage;