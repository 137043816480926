import "@fontsource/montserrat";
import "animate.css";
import './App.css';
import { useState } from "react";
import { Intro } from './scenes/Intro';
import BirthdayCard from "./scenes/BirthdayCard";
import Final from "./scenes/Final";

function App() {
	const [activeScene, setActiveScene] = useState('intro');

	return (
		<div className="flex h-full w-full bg-black items-center justify-center">
			<Intro activeScene={activeScene} setActiveScene={setActiveScene} />
			<BirthdayCard activeScene={activeScene} setActiveScene={setActiveScene} />
			<Final activeScene={activeScene} setActiveScene={setActiveScene} />
		</div>
	);
}

export default App;
